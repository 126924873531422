import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Col } from "reactstrap"
import Image from "../image"

const RadioHourCovers = () => {
  const data = useStaticQuery(graphql`
    query {
      allRadioHourJson {
        edges {
          node {
            coverImage
            title
            date
            soundcloud
          }
        }
      }
    }
  `)
  let shows = []
  let index = Object.keys(data)[0]
  let edges = data[index].edges
  edges.forEach(item => {
    shows.push(item.node)
  })
  shows = shows
    .slice()
    .sort((a, b) => b.title.split(" ")[1] - a.title.split(" ")[1])
  let display = shows.map((i, idx) => {
    let date = new Date(i.date)
    var d = date.getDate()
    var m = date.getMonth() + 1
    var y = date.getFullYear()
    let xxx = (m <= 9 ? "0" + m : m) + "." + (d <= 9 ? "0" + d : d) + "." + y

    return (
      <Col
        key={idx}
        className={
          "d-flex justify-content-center rh-cover-container tile-" +
          idx +
          (idx > 0 ? " halfWidth" : "")
        }
        md={{ size: 4 }}
      >
        <a
          href={i.soundcloud}
          target="_blank"
          rel="noopener noreferrer"
          className="w-100 h-100 position-relative"
        >
          <Image
            className="cover-image-wrapper"
            src={i.coverImage}
            alt={i.title}
          />
          <div className="cover-hover-container ">
            <div className="rh-cover-hover m-0">{xxx}</div>
          </div>
        </a>
      </Col>
    )
  })
  return display
}

export default RadioHourCovers
