import React, { useEffect } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Container, Row, Col } from "reactstrap"

import SEO from "../components/seo"
import Layout from "../components/layout"

import cart_icon from "../../static/icons/cart-full.svg"
import cart_icon_empty from "../../static/icons/cart-empty.svg"

import RadioHourCovers from "../components/radioHour/radioHourCovers"
import rh_logo from "../images/radio-hour/radio_hour_logo.png"
import chain_logo from "../../static/logos/PIJ-logomark-fill-white.svg"

import { setOrderModalOpen } from "../state/cart"

const RadioHour = ({ orderDetails, dispatch }) => {
  let bag = (
    <img
      onClick={() => dispatch(setOrderModalOpen(true))}
      className="bag"
      src={cart_icon_empty}
      alt="bag"
    />
  )

  if (orderDetails.length > 0) {
    bag = (
      <div className="bag">
        <img
          onClick={() => dispatch(setOrderModalOpen(true))}
          src={cart_icon}
          alt="bag"
        />
        <span className="bag-count">{orderDetails.length}</span>
      </div>
    )
  }
  return (
    <Layout>
      <Container fluid className="rh-body-container">
        <SEO title="Radio Hour" />
        <Row className="rh-logo-row">
          <Col className="rh-logo-container" md={{ size: 12 }}>
            <img src={rh_logo} alt="rh-logo" className="rh-logo" />
          </Col>
        </Row>
        <Row className="rh-covers-row d-flex justify-content-center">
          <Col
            sm={{ size: 12 }}
            md={{ size: 12 }}
            className="d-flex justify-content-center"
          >
            <Row className="w-100">
              <RadioHourCovers />
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default connect(
  state => ({
    orderDetails: state.cart.orderDetails,
  }),
  null
)(RadioHour)
